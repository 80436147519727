/**
 * @generated SignedSource<<251f82ca2867b5c80acee032f848c18f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditModeRealmData$data = {
  readonly blocks: ReadonlyArray<{
    readonly __typename: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SeriesEditModeBlockData" | "TextEditModeBlockData" | "TitleEditModeBlockData" | "VideoEditModeBlockData">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"EditModeFormRealmData">;
  readonly " $fragmentType": "EditModeRealmData";
};
export type EditModeRealmData$key = {
  readonly " $data"?: EditModeRealmData$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditModeRealmData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditModeRealmData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "blocks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TitleEditModeBlockData"
            }
          ],
          "type": "TitleBlock",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TextEditModeBlockData"
            }
          ],
          "type": "TextBlock",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SeriesEditModeBlockData"
            }
          ],
          "type": "SeriesBlock",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "VideoEditModeBlockData"
            }
          ],
          "type": "VideoBlock",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditModeFormRealmData"
    }
  ],
  "type": "Realm",
  "abstractKey": null
};

(node as any).hash = "f72d41472391bd90c792fcf11324117a";

export default node;
