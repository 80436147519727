/**
 * @generated SignedSource<<13d59758167f0ad9ef40ee524777a327>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VideoPageDirectOpencastLinkQuery$variables = {
  id: string;
};
export type VideoPageDirectOpencastLinkQuery$data = {
  readonly event: {
    readonly " $fragmentSpreads": FragmentRefs<"VideoPageEventData">;
  } | null | undefined;
  readonly realm: {
    readonly " $fragmentSpreads": FragmentRefs<"NavigationData" | "VideoPageRealmData">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UserData">;
};
export type VideoPageDirectOpencastLinkQuery = {
  response: VideoPageDirectOpencastLinkQuery$data;
  variables: VideoPageDirectOpencastLinkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creators",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLive",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "opencastId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uri",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resolution",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMainRoot",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoPageDirectOpencastLinkQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserData"
      },
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "eventByOpencastId",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VideoPageEventData"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "realm",
        "args": null,
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "rootRealm",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VideoPageRealmData"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NavigationData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoPageDirectOpencastLinkQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUpload",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseStudio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseEditor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canCreateUserRealm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canFindUnlisted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userRole",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "event",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "eventByOpencastId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isEvent"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dummy",
                "storageKey": null
              }
            ],
            "type": "NotAllowed",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "metadata",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canWrite",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SyncedEventData",
                "kind": "LinkedField",
                "name": "syncedData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Track",
                    "kind": "LinkedField",
                    "name": "tracks",
                    "plural": true,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "flavor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mimetype",
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isMaster",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Caption",
                    "kind": "LinkedField",
                    "name": "captions",
                    "plural": true,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lang",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Segment",
                    "kind": "LinkedField",
                    "name": "segments",
                    "plural": true,
                    "selections": [
                      (v13/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Series",
                "kind": "LinkedField",
                "name": "series",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SyncedSeriesData",
                    "kind": "LinkedField",
                    "name": "syncedData",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthorizedEvent",
                    "kind": "LinkedField",
                    "name": "events",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v5/*: any*/),
                      (v7/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SyncedEventData",
                        "kind": "LinkedField",
                        "name": "syncedData",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Track",
                            "kind": "LinkedField",
                            "name": "tracks",
                            "plural": true,
                            "selections": [
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "AuthorizedEvent",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "realm",
        "args": null,
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "rootRealm",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "ancestors",
            "plural": true,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "childOrder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isUserRoot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "parent",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "568aed58ab40008aa61e8a36f018577c",
    "id": null,
    "metadata": {},
    "name": "VideoPageDirectOpencastLinkQuery",
    "operationKind": "query",
    "text": "query VideoPageDirectOpencastLinkQuery(\n  $id: String!\n) {\n  ...UserData\n  event: eventByOpencastId(id: $id) {\n    __typename\n    ...VideoPageEventData\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  realm: rootRealm {\n    ...VideoPageRealmData\n    ...NavigationData\n    id\n  }\n}\n\nfragment NavigationData on Realm {\n  id\n  name\n  childOrder\n  isUserRoot\n  children {\n    id\n    name\n    path\n  }\n  parent {\n    isMainRoot\n    name\n    path\n    id\n  }\n}\n\nfragment SeriesBlockSeriesData on Series {\n  title\n  syncedData {\n    description\n  }\n  events {\n    id\n    title\n    created\n    creators\n    isLive\n    description\n    syncedData {\n      duration\n      thumbnail\n      startTime\n      endTime\n      tracks {\n        resolution\n      }\n    }\n  }\n}\n\nfragment UserData on Query {\n  currentUser {\n    username\n    displayName\n    canUpload\n    canUseStudio\n    canUseEditor\n    canCreateUserRealm\n    canFindUnlisted\n    roles\n    userRole\n  }\n}\n\nfragment VideoPageEventData on Event {\n  __isEvent: __typename\n  __typename\n  ... on NotAllowed {\n    dummy\n  }\n  ... on AuthorizedEvent {\n    id\n    title\n    description\n    creators\n    created\n    isLive\n    opencastId\n    metadata\n    canWrite\n    syncedData {\n      updated\n      duration\n      thumbnail\n      startTime\n      endTime\n      tracks {\n        uri\n        flavor\n        mimetype\n        resolution\n        isMaster\n      }\n      captions {\n        uri\n        lang\n      }\n      segments {\n        uri\n        startTime\n      }\n    }\n    series {\n      id\n      opencastId\n      title\n      ...SeriesBlockSeriesData\n    }\n  }\n}\n\nfragment VideoPageRealmData on Realm {\n  name\n  path\n  isMainRoot\n  ancestors {\n    name\n    path\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "84075cbd3f0e44cc6e03e3b96009062d";

export default node;
