/**
 * @generated SignedSource<<b59d3e8324752a87d25d65ff9b616c3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SeriesByIdQuery$variables = {
  id: string;
};
export type SeriesByIdQuery$data = {
  readonly rootRealm: {
    readonly " $fragmentSpreads": FragmentRefs<"NavigationData">;
  };
  readonly series: {
    readonly " $fragmentSpreads": FragmentRefs<"SeriesRouteData">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserData">;
};
export type SeriesByIdQuery = {
  response: SeriesByIdQuery$data;
  variables: SeriesByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SeriesByIdQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserData"
      },
      {
        "alias": "series",
        "args": (v1/*: any*/),
        "concreteType": "Series",
        "kind": "LinkedField",
        "name": "seriesById",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SeriesRouteData"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "rootRealm",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NavigationData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SeriesByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUpload",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseStudio",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUseEditor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canCreateUserRealm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canFindUnlisted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userRole",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "series",
        "args": (v1/*: any*/),
        "concreteType": "Series",
        "kind": "LinkedField",
        "name": "seriesById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SyncedSeriesData",
            "kind": "LinkedField",
            "name": "syncedData",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthorizedEvent",
            "kind": "LinkedField",
            "name": "events",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creators",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLive",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SyncedEventData",
                "kind": "LinkedField",
                "name": "syncedData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Track",
                    "kind": "LinkedField",
                    "name": "tracks",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "resolution",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Realm",
        "kind": "LinkedField",
        "name": "rootRealm",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "childOrder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isUserRoot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Realm",
            "kind": "LinkedField",
            "name": "parent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isMainRoot",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fda3226364b62a4bb9269f8308c93154",
    "id": null,
    "metadata": {},
    "name": "SeriesByIdQuery",
    "operationKind": "query",
    "text": "query SeriesByIdQuery(\n  $id: ID!\n) {\n  ...UserData\n  series: seriesById(id: $id) {\n    ...SeriesRouteData\n    id\n  }\n  rootRealm {\n    ...NavigationData\n    id\n  }\n}\n\nfragment NavigationData on Realm {\n  id\n  name\n  childOrder\n  isUserRoot\n  children {\n    id\n    name\n    path\n  }\n  parent {\n    isMainRoot\n    name\n    path\n    id\n  }\n}\n\nfragment SeriesBlockSeriesData on Series {\n  title\n  syncedData {\n    description\n  }\n  events {\n    id\n    title\n    created\n    creators\n    isLive\n    description\n    syncedData {\n      duration\n      thumbnail\n      startTime\n      endTime\n      tracks {\n        resolution\n      }\n    }\n  }\n}\n\nfragment SeriesRouteData on Series {\n  title\n  syncedData {\n    description\n  }\n  ...SeriesBlockSeriesData\n}\n\nfragment UserData on Query {\n  currentUser {\n    username\n    displayName\n    canUpload\n    canUseStudio\n    canUseEditor\n    canCreateUserRealm\n    canFindUnlisted\n    roles\n    userRole\n  }\n}\n"
  }
};
})();

(node as any).hash = "e04fd7e8c3786465bd6981e41d7f5c48";

export default node;
