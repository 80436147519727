/**
 * @generated SignedSource<<821d1e148ad03a157c482138c9f0b70f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SeriesBlockSeriesData$data = {
  readonly events: ReadonlyArray<{
    readonly created: string;
    readonly creators: ReadonlyArray<string>;
    readonly description: string | null | undefined;
    readonly id: string;
    readonly isLive: boolean;
    readonly syncedData: {
      readonly duration: number;
      readonly endTime: string | null | undefined;
      readonly startTime: string | null | undefined;
      readonly thumbnail: string | null | undefined;
      readonly tracks: ReadonlyArray<{
        readonly resolution: ReadonlyArray<number> | null | undefined;
      }>;
    } | null | undefined;
    readonly title: string;
  }>;
  readonly syncedData: {
    readonly description: string | null | undefined;
  } | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "SeriesBlockSeriesData";
};
export type SeriesBlockSeriesData$key = {
  readonly " $data"?: SeriesBlockSeriesData$data;
  readonly " $fragmentSpreads": FragmentRefs<"SeriesBlockSeriesData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SeriesBlockSeriesData",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SyncedSeriesData",
      "kind": "LinkedField",
      "name": "syncedData",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuthorizedEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creators",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLive",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SyncedEventData",
          "kind": "LinkedField",
          "name": "syncedData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Track",
              "kind": "LinkedField",
              "name": "tracks",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "resolution",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Series",
  "abstractKey": null
};
})();

(node as any).hash = "a47b4c778c356530badc750ba15ccedb";

export default node;
