/**
 * @generated SignedSource<<22bdb3236d3a116e19dc8358630cadb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VideoListLayout = "GALLERY" | "LIST" | "SLIDER" | "%future added value";
export type VideoListOrder = "AZ" | "NEW_TO_OLD" | "OLD_TO_NEW" | "ZA" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SeriesEditModeBlockData$data = {
  readonly layout: VideoListLayout;
  readonly order: VideoListOrder;
  readonly series: {
    readonly id: string;
    readonly opencastId: string;
    readonly syncedData: {
      readonly description: string | null | undefined;
    } | null | undefined;
    readonly title: string;
  } | null | undefined;
  readonly showMetadata: boolean;
  readonly showTitle: boolean;
  readonly " $fragmentType": "SeriesEditModeBlockData";
};
export type SeriesEditModeBlockData$key = {
  readonly " $data"?: SeriesEditModeBlockData$data;
  readonly " $fragmentSpreads": FragmentRefs<"SeriesEditModeBlockData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SeriesEditModeBlockData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Series",
      "kind": "LinkedField",
      "name": "series",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "opencastId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SyncedSeriesData",
          "kind": "LinkedField",
          "name": "syncedData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showMetadata",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "layout",
      "storageKey": null
    }
  ],
  "type": "SeriesBlock",
  "abstractKey": null
};

(node as any).hash = "4917ddf1f4366883075224393111c06d";

export default node;
