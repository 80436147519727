/**
 * @generated SignedSource<<72afed1e0940c5505c7ee794827d0b1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VideoListLayout = "GALLERY" | "LIST" | "SLIDER" | "%future added value";
export type VideoListOrder = "AZ" | "NEW_TO_OLD" | "OLD_TO_NEW" | "ZA" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SeriesBlockData$data = {
  readonly layout: VideoListLayout;
  readonly order: VideoListOrder;
  readonly series: {
    readonly " $fragmentSpreads": FragmentRefs<"SeriesBlockSeriesData">;
  } | null | undefined;
  readonly showMetadata: boolean;
  readonly showTitle: boolean;
  readonly " $fragmentType": "SeriesBlockData";
};
export type SeriesBlockData$key = {
  readonly " $data"?: SeriesBlockData$data;
  readonly " $fragmentSpreads": FragmentRefs<"SeriesBlockData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SeriesBlockData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Series",
      "kind": "LinkedField",
      "name": "series",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeriesBlockSeriesData"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showMetadata",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "layout",
      "storageKey": null
    }
  ],
  "type": "SeriesBlock",
  "abstractKey": null
};

(node as any).hash = "c52c5df809855ed2d124ac3c2ea726b6";

export default node;
